import React, { useState, useEffect } from 'react';
import AdWhitelistQuery from '../models/Adwhitelist/AdWhitelistQuery';
import fetcher from '../utils/fetcher';
import PropTypes from 'prop-types';

const initialSiteState = {
  adSettings: undefined,
};

export const SiteContext = React.createContext(initialSiteState);

// eslint-disable-next-line react/prop-types
export const SiteProvider = ({ children }) => {
  const [adSettings, setAdSettings] = useState;
  async function updateAdSettings() {
    try {
      const data = (await fetcher)(AdWhitelistQuery, {
        slug: 'yourclassical/ad-whitelist',
      });

      if (data?.adWhitelist?.json) {
        setAdSettings(JSON.parse(data.adWhitelist.json));
      }
    } catch (e) {
      console.error(`Error trying to update ad settings: ${e}`);
    }
  }
  useEffect(() => {
    if (!adSettings) {
      updateAdSettings();
    }
  }, []);

  return (
    <SiteContext.Provider
      value={{
        ...initialSiteState,

        adSettings,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

SiteProvider.prototype = {
  children: PropTypes.node,
};
