import React, { FC } from 'react';

interface Props {
  color?: string;
  elementClass?: string;
}

const IconChevronLeft: FC<Props> = ({
  color = 'var(--color-mpr-dark-gray)',
  elementClass,
}) => {
  return (
    <svg
      className={`icon icon-chevronLeft ${elementClass ? elementClass : ''}`}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Left</title>
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" fill={color} />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

export default IconChevronLeft;
