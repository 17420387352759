import React, { useEffect, useState } from 'react';
import NowPlayingClient from 'nowplaying-client-2';
import PropTypes from 'prop-types';

export const defaultNotification = {
  info: {
    alert: false,
    show_on: [''],
    url: 'http://www.classicalmpr.org/playlist/',
    prefix: 'Something important is happening',
    title: 'Click on this link to get the important details',
  },
  hideAlert: false,
  setHideAlert: (hideAlert) => undefined,
};

export const PotlatchNotificationContext =
  React.createContext(defaultNotification);

export const PotlatchNotificationProvider = ({ children }) => {
  const [state, setState] = useState({
    ...defaultNotification,
  });

  useEffect(() => {
    const client = new NowPlayingClient({
      server: 'wss://nowplayingv2.publicradio.org',
    });

    const potlatchRegistration = client.register_callback(
      'mprnews/info-alert-live',
      'potlatch',
      (data) => {
        setState({ ...data, hideAlert: false });
      }
    );

    return () => {
      potlatchRegistration.unregister();
    };
  }, []);

  const setHideAlert = (hideAlert) => {
    setState((prevState) => {
      return { ...prevState, hideAlert };
    });

    setTimeout(() => {
      setState((prevState) => {
        return { ...prevState, hideAlert: false };
      });
    }, 60 * 5 * 1000);
  };

  return (
    <PotlatchNotificationContext.Provider
      value={{
        ...state,
        setHideAlert,
      }}
    >
      {children}
    </PotlatchNotificationContext.Provider>
  );
};

export default PotlatchNotificationContext;

PotlatchNotificationProvider.propTypes = {
  children: PropTypes.node,
};
