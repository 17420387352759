/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import AdvertisementBanner from 'components/Advertisement/AdvertisementBanner';
import Menu from './Menu';
import IconClose from 'components/Icons/IconClose';
import { useSession } from 'next-auth/react';
import Modal from 'components/Modal';
import ConfirmUser from 'components/Auth/ConfirmUser';
import ResetPassword from 'components/Auth/ResetPassword';
import SignIn from 'components/Auth/SignIn';
import NewUser from 'components/Auth/NewUser';
import { useRouter } from 'next/router';
import IconNav from 'components/Icons/IconNav';
import IconMicrophone from 'components/Icons/IconMicrophone';
import IconPodcast from 'components/Icons/IconPodcast';
import IconGive from 'components/Icons/IconGive';
import IconSearch from 'components/Icons/IconSearch';
import IconPerson from 'components/Icons/IconPerson';
import IconLock from 'components/Icons/IconLock';
import IconChevron from 'components/Icons/IconChevron';
import IconMail from 'components/Icons/IconMail';

interface Props {
  isHome?: boolean;
  alert?: JSX.Element;
}

const Header: FC<Props> = ({ isHome = false, alert }) => {
  const sessionData = useSession();
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const [waysToGiveMenu, setWaysToGiveMenu] = useState(false);
  const [usernameMenu, setUsernameMenu] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userImage, setUserImage] = useState(
    sessionData?.data?.user.image || undefined
  );

  useEffect(() => {
    const closeMenuIfClickedOutside = (e: Event) => {
      const targetEl = e.target as HTMLElement;
      if (
        targetEl.matches &&
        !(
          targetEl.matches('.menu') ||
          targetEl.matches('.menu *') ||
          targetEl.matches('.menu-opening-btn') ||
          targetEl.matches('.menu-opening-btn *')
        )
      ) {
        setMenuOpen(false);
        setWaysToGiveMenu(false);
        setUsernameMenu(false);
      }
    };
    document.addEventListener('mousedown', closeMenuIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', closeMenuIfClickedOutside);
    };
  }, [menuOpen]);

  useEffect(() => {
    setUserImage(sessionData.data?.user.image ?? undefined);
  }, [sessionData]);

  return (
    <>
      <header className={`header ${isHome ? 'header-home' : ''}`}>
        <div className="wrapper header-contents">
          <div className="container">
            <div className="header-contents-inner">
              <div className="header-top">
                <Link href="/" passHref>
                  <picture>
                    <source
                      srcSet="/img/MPR-news-logo.svg"
                      media="(min-width: 32em)"
                    />
                    <img
                      src="/img/mpr-news-logo-thumbnail.svg"
                      className="header-logo"
                      alt="MPR News"
                    />
                  </picture>
                  <div className="header-tagline">
                    Stay Curious. Stay Connected.
                  </div>
                </Link>
                <div className="header-ctas">
                  <Link
                    href="/newsletters"
                    passHref
                    className="btn btn-slim btn-newsletters small-gap"
                  >
                    <IconMail />
                    <span>Newsletters</span>
                  </Link>
                  <Link
                    href="https://support.mpr.org/news-web"
                    passHref
                    className="btn btn-primary btn-slim small-gap"
                    target="_blank"
                    aria-label="Donate Now - Link opens in a new tab"
                  >
                    <span className="type-lg icon-heart">♡</span>Donate Now
                  </Link>
                </div>
              </div>
              <nav className="header-nav">
                <ul className="header-nav-mobile">
                  <li>
                    <button
                      className="menu-opening-btn"
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                        setWaysToGiveMenu(false);
                        setUsernameMenu(false);
                      }}
                      aria-label={
                        !menuOpen ? 'Menu - Opens menu' : 'Menu - Closes menu'
                      }
                    >
                      {!menuOpen ? (
                        <IconNav title="" /* decorative */ />
                      ) : (
                        <IconClose className="stroke-icon" />
                      )}
                      Menu
                    </button>
                    {menuOpen && <Menu selfCloser={setMenuOpen} />}
                  </li>
                  <li className="header-nav-small-mobile-item">
                    <Link href="/search" passHref>
                      <IconSearch title="Search" />
                    </Link>
                  </li>
                  <li className="header-nav-large-mobile-item">
                    <Link href="/search" passHref>
                      <IconSearch title="" /* decorative */ />
                      Search
                    </Link>
                  </li>
                  <li className="expander" aria-hidden></li>

                  {sessionData.status === 'authenticated' ? (
                    <li>
                      <button
                        className="header-user menu-opening-btn"
                        onClick={() => {
                          setUsernameMenu(!usernameMenu);
                          setMenuOpen(false);
                          setWaysToGiveMenu(false);
                        }}
                      >
                        {userImage ? (
                          <img
                            src={userImage}
                            alt={
                              sessionData?.data.user?.preferred_username ||
                              'user image'
                            }
                          />
                        ) : (
                          <img
                            src="/img/default-person.png"
                            alt="Placeholder"
                          />
                        )}
                        <IconChevron
                          direction={usernameMenu ? 'up' : 'down'}
                          color="var(--color-white)"
                          className="stroke-icon"
                          title={usernameMenu ? 'Close user menu' : 'User menu'}
                        />
                      </button>

                      {usernameMenu && (
                        <Menu
                          initialMenu="user-account"
                          hasSubmenus={false}
                          selfCloser={setUsernameMenu}
                        />
                      )}
                    </li>
                  ) : (
                    <>
                      <li>
                        <button
                          onClick={() => {
                            setShowSignInModal(true);
                          }}
                        >
                          <IconLock
                            className="stroke-icon"
                            title="" /* decorative */
                          />
                          <span className="multi-word-item">Sign in</span>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            setShowNewUserModal(true);
                          }}
                        >
                          <IconPerson
                            className="stroke-icon"
                            title="" /* decorative */
                          />
                          <span className="multi-word-item">Sign up</span>
                        </button>
                      </li>
                    </>
                  )}
                </ul>
                <div className="header-nav-desktop-outer">
                  <ul className="header-nav-desktop">
                    <li>
                      <button
                        className="menu-opening-btn"
                        onClick={() => {
                          setMenuOpen(!menuOpen);
                          setWaysToGiveMenu(false);
                          setUsernameMenu(false);
                        }}
                      >
                        {!menuOpen ? (
                          <IconNav title="" /* decorative */ />
                        ) : (
                          <IconClose className="stroke-icon" />
                        )}
                        Menu
                      </button>
                      {menuOpen && (
                        <Menu selfCloser={setMenuOpen} largeMenu={true} />
                      )}
                    </li>
                    <li>
                      <Link href="/podcasts" passHref>
                        <IconPodcast
                          className="stroke-icon"
                          title="" /* decorative */
                        />
                        Programs & Podcasts
                      </Link>
                    </li>
                    <li>
                      <Link href="/schedule" passHref>
                        <IconMicrophone
                          className="stroke-icon"
                          title="" /* decorative */
                        />
                        Schedule
                      </Link>
                    </li>
                    <li>
                      <button
                        className="menu-opening-btn"
                        onClick={() => {
                          setWaysToGiveMenu(!waysToGiveMenu);
                          setMenuOpen(false);
                          setUsernameMenu(false);
                        }}
                      >
                        {!waysToGiveMenu ? (
                          <IconGive
                            className="stroke-icon"
                            title="" /* decorative */
                          />
                        ) : (
                          <IconClose className="stroke-icon" />
                        )}
                        Ways to Give
                      </button>
                      {waysToGiveMenu && (
                        <Menu initialMenu="ways-to-give" hasSubmenus={false} />
                      )}
                    </li>
                    <li className="header-nav-large-mobile-item">
                      <Link href="/search" passHref>
                        <IconSearch title="" /* decorative */ />
                        Search
                      </Link>
                    </li>
                    {sessionData.status == 'authenticated' ? (
                      <li>
                        <button
                          className="header-user menu-opening-btn"
                          onClick={() => {
                            setUsernameMenu(!usernameMenu);
                            setMenuOpen(false);
                            setWaysToGiveMenu(false);
                          }}
                        >
                          {userImage ? (
                            <img
                              src={userImage}
                              alt={
                                sessionData?.data.user?.preferred_username ||
                                'user image'
                              }
                            />
                          ) : (
                            <img
                              src="/img/default-person.png"
                              alt="Placeholder"
                            />
                          )}

                          {sessionData.data.user.given_name}
                          <IconChevron
                            direction={usernameMenu ? 'up' : 'down'}
                            color="var(--color-white)"
                            className="stroke-icon"
                            title={
                              usernameMenu
                                ? ' - Close user menu'
                                : ' - User menu'
                            }
                          />
                        </button>

                        {usernameMenu && (
                          <Menu
                            initialMenu="user-account"
                            hasSubmenus={false}
                            selfCloser={setUsernameMenu}
                          />
                        )}
                      </li>
                    ) : (
                      <>
                        <li>
                          <button
                            onClick={() => {
                              setShowSignInModal(true);
                            }}
                          >
                            <IconLock
                              className="stroke-icon"
                              title="" /* decorative */
                            />
                            Sign in
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => {
                              setShowNewUserModal(true);
                            }}
                          >
                            <IconPerson
                              className="stroke-icon"
                              title="" /* decorative */
                            />
                            Sign up
                          </button>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        {alert ? alert : ''}
        {!isHome && (
          <div className="header-ad">
            <AdvertisementBanner id="mpr-ad-1" />
          </div>
        )}
      </header>
      <Modal
        noBorderRadiusOnTablet
        onClose={() => setShowNewUserModal(false)}
        show={showNewUserModal}
      >
        <NewUser
          onClose={() => setShowNewUserModal(false)}
          signInHandler={(e) => {
            e.preventDefault();
            setShowNewUserModal(false);
            setShowSignInModal(true);
          }}
        />
      </Modal>
      <Modal
        noBorderRadiusOnTablet
        onClose={() => setShowSignInModal(false)}
        show={showSignInModal}
      >
        <SignIn
          onClose={() => setShowSignInModal(false)}
          newUserHandler={(e) => {
            e.preventDefault();
            setShowSignInModal(false);
            setShowNewUserModal(true);
          }}
          forgotPasswordHandler={(e) => {
            e.preventDefault();
            setShowSignInModal(false);
            setShowForgotPasswordModal(true);
          }}
          callbackUrl={router.asPath}
        />
      </Modal>
      <Modal
        noBorderRadiusOnTablet
        onClose={() => setShowForgotPasswordModal(false)}
        show={showForgotPasswordModal}
      >
        <ResetPassword onClose={() => setShowForgotPasswordModal(false)} />
      </Modal>
      <Modal
        noBorderRadiusOnTablet
        onClose={() => setShowConfirmationModal(false)}
        show={showConfirmationModal}
      >
        <ConfirmUser
          onClose={() => setShowConfirmationModal(false)}
          signInHandler={(e) => {
            e.preventDefault();
            setShowConfirmationModal(false);
            setShowSignInModal(true);
          }}
        />
      </Modal>
    </>
  );
};

export default Header;
