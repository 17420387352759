import React from 'react';
import PropTypes from 'prop-types';

const IconElectionBox = (props) => {
  return (
    <svg
      width="100"
      height="100"
      className={`icon icon-electionBox ${
        props.elementClass ? props.elementClass : ''
      }`}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Ballot Box</title>
      <path
        d="M95.3,62.2l-8.9-16.4c-1.8-3.3-5.3-5.4-9.1-5.4h-4.4V20.7c0-2.4-2-4.4-4.4-4.4H31.5c-2.4,0-4.4,2-4.4,4.4v19.6h-4.4
		c-3.8,0-7.3,2.1-9.1,5.4L4.7,62.2c-0.8,1.5-1.3,3.2-1.3,5v21.5c0,3.9,3.1,7,7,7c0,0,0,0,0,0h79.2c3.9,0,7-3.1,7-7v0l0,0V67.2
		C96.6,65.4,96.1,63.7,95.3,62.2z M91.4,69.8v18.9c0,1-0.8,1.8-1.8,1.8H10.4c-1,0-1.8-0.8-1.8-1.8l0,0V67.2l0,0c0-0.4,0-0.8,0.1-1.1
		h82.5c0.1,0.4,0.1,0.7,0.1,1.1l0,0L91.4,69.8z M22.7,45.5h4.4v5h-2.3c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h50.2
		c1.4,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6h-2.3v-5h4.4c1.9,0,3.6,1,4.5,2.7l6.9,12.6H11.3l6.9-12.6C19.1,46.6,20.8,45.5,22.7,45.5z
		 M32.3,40.3V21.5h35.3v29H32.3V40.3z"
      />
      <path
        d="M43.9,39.4l1.9,2.6l0.7,0.9c0.5,0.7,1.3,1.1,2.1,1.1l0,0c0.8,0,1.6-0.4,2.1-1l0.7-0.9l1.9-2.6l7-9.3
		c0.9-1.1,0.8-2.7-0.4-3.7c-1.1-0.9-2.7-0.8-3.7,0.4L56.1,27l-7.6,10l-2.7-3.8c-0.8-1.2-2.5-1.4-3.6-0.6c-1.2,0.8-1.4,2.5-0.6,3.6
		L43.9,39.4z"
      />
    </svg>
  );
};

IconElectionBox.propTypes = {
  elementClass: PropTypes.string,
};

export default IconElectionBox;
