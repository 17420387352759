import React, { FC, useState } from 'react';
import { Link } from '@apmg/titan';
import Head from 'next/head';
import IconCloseModal from 'components/Icons/IconClose';

interface Props {
  info: {
    alert: boolean;
    show_on: string[];
    prefix: string;
    title: string;
    url: string;
  };
  hideAlert?: boolean;
  setHideAlert?: (hideAlert: boolean) => undefined;
}

const Alert: FC<Props> = ({ info, hideAlert, setHideAlert }) => {
  const [alertShown, setAlertShown] = useState(!hideAlert);

  return (
    <>
      {info.alert && alertShown && !hideAlert && (
        <div className="alert">
          <Head>
            <link href="/css/components/alert.css" rel="stylesheet" />
          </Head>
          <div className="container alert-inner">
            <Link href={info.url}>
              <div className="alert-content">
                {info.prefix && (
                  <div className="alert-tag type-sm">{info.prefix}</div>
                )}
                {info.title && info.title}
              </div>
            </Link>
            <button
              className="alert-close"
              onClick={() => {
                setAlertShown(false);
                setHideAlert && setHideAlert(true);
              }}
            >
              <IconCloseModal
                color="var(--color-white)"
                width="1.375rem"
                height="1.375rem"
                strokeWidth="1.2"
                title="Close Alert"
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
