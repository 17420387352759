import React, { FC } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  title?: string;
  className?: string;
}

const IconLock: FC<Props> = ({
  width = '15',
  height = '16',
  color = 'var(--color-white)',
  title = '',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={color}
      fill="transparent"
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.721 10.3333V11.8889M3.05433 15H12.3877C13.2468 15 13.9432 14.3036 13.9432 13.4444V8.77778C13.9432 7.91867 13.2468 7.22222 12.3877 7.22222H3.05433C2.19523 7.22222 1.49878 7.91867 1.49878 8.77778V13.4444C1.49878 14.3036 2.19523 15 3.05433 15ZM10.8321 7.22222V4.11111C10.8321 2.39289 9.43922 1 7.721 1C6.00278 1 4.60989 2.39289 4.60989 4.11111V7.22222H10.8321Z"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      {title && <title>{title}</title>}
    </svg>
  );
};

export default IconLock;
