import React, { createContext, useState } from 'react';

export interface AdCategoriesContextType {
  adCategories: string[];
  primaryCategory: string;
  setAdCategories: (value: any) => void;
  setPrimaryCategory: (itm: string) => void;
}
const AdCategoriesContext = createContext<AdCategoriesContextType | null>(null);

export const AdCategoriesContextProvider: React.FC<{
  children: React.ReactNode;
  primaryCategory: string;
  adCategories: string[];
}> = (props) => {
  const [adCategories, setAdCategories] = useState(props.adCategories);
  const [primaryCategory, setPrimaryCategory] = useState(props.primaryCategory);
  return (
    <AdCategoriesContext.Provider
      value={{
        adCategories,
        setAdCategories,
        primaryCategory,
        setPrimaryCategory,
      }}
    >
      {props.children}
    </AdCategoriesContext.Provider>
  );
};

export default AdCategoriesContext;
