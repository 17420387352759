export const menuData = [
  {
    name: 'Topics',
    items: [
      {
        name: 'Weather',
        url: '/weather',
      },
      {
        name: 'Politics & Elections',
        url: '/politics',
      },
      {
        name: 'Education',
        url: '/education',
      },
      {
        name: 'Environment',
        url: '/environment',
      },
      {
        name: 'Health',
        url: '/health',
      },
      {
        name: 'Arts & Culture',
        url: '/arts',
      },
      {
        name: 'Books',
        url: '/books',
      },
      {
        name: 'Money',
        url: '/business/money',
      },
      {
        name: 'Sports',
        url: '/sports',
      },
      {
        name: 'Race',
        url: '/social-issues/race',
      },
    ],
  },
  {
    name: 'Featured',
    items: [
      {
        name: 'Talking Sense',
        url: '/talking-sense',
      },
      {
        name: 'APM Reports: Investigative',
        url: 'https://www.apmreports.org/',
      },
      {
        name: 'Native News',
        url: '/native-news',
      },
      {
        name: 'North Star Journey',
        url: '/north-star-journey',
      },
      {
        name: 'Reverb',
        url: '/reverb',
      },
      {
        name: 'Archive',
        url: 'https://archive.mpr.org/',
      },
    ],
  },
  {
    name: 'Programs',
    items: [
      {
        name: 'Morning Edition',
        url: '/shows/morning-edition',
      },
      {
        name: 'All Things Considered',
        url: '/shows/all-things-considered',
      },
      {
        name: 'MPR News with Angela Davis',
        url: '/shows/angela-davis',
      },
      {
        name: 'Minnesota Now',
        url: '/shows/minnesotanow',
      },
      {
        name: 'Talking Volumes',
        url: '/arts/books/talking-volumes',
      },
      {
        name: 'Big Books and Bold Ideas',
        url: '/shows/kerri-miller',
      },
      {
        name: 'Schedule',
        url: '/schedule',
        class: 'menu-item-higlighted',
      },
    ],
  },
  {
    name: 'About Us',
    items: [
      {
        name: 'About MPR News',
        url: '/about',
      },
      {
        name: 'Events',
        url: '/events',
      },
    ],
  },
];

export default menuData;
