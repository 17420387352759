import { useEffect, useContext } from 'react';
import AdCategoriesContext from 'context/AdCategoriesContext';
import { globals } from 'config/globals';
import { useRouter } from 'next/router';

const useAds = ({
  id,
  sizes,
  sizeMap,
  position = 'atf',
  googletag = false, // for testing only
}) => {
  const context = useContext(AdCategoriesContext);
  const router = useRouter();

  let correctedAdUnitPath;
  try {
    correctedAdUnitPath =
      typeof context.primaryCategory == 'string'
        ? `${globals.adUnitPath}/${context.primaryCategory.slice(0, 40)}`
        : globals.adUnitPath;
  } catch {
    correctedAdUnitPath = globals.adUnitPath;
  }

  useEffect(() => {
    googletag = googletag || window.googletag || {};
    // googletag.cmd = googletag.cmd || [];
    if (!googletag.cmd) googletag.cmd = [];

    let slot;

    googletag.cmd.push(function () {
      googletag.pubads().collapseEmptyDivs();
      slot = googletag
        .defineSlot(correctedAdUnitPath, sizes, id)
        // .defineSizeMapping(builtMapping)
        .addService(googletag.pubads())
        .setTargeting('pos', position)
        .setTargeting('primaryCategory', context?.primaryCategory);

      const adMapping = googletag.sizeMapping();
      for (const [screenWidth, adSize] of Object.entries(sizeMap)) {
        adMapping.addSize([parseInt(screenWidth), 0], adSize);
      }
      // const builtMapping = adMapping.build();

      if (
        Array.isArray(context?.adCategories) &&
        context?.adCategories.length > 0 &&
        typeof context.adCategories[0] != 'undefined'
      ) {
        // console.log(slot);
        // console.log('about to setTargeting for slot', slot.getAdUnitPath());
        slot.setTargeting(
          'categories',
          context?.adCategories.map((cat) => {
            return cat.slice(0, 40);
          })
        );
      }
      googletag.pubads().enableLazyLoad();
      googletag.pubads().disableInitialLoad();

      googletag.enableServices();
      googletag.display(id);
      googletag.pubads().refresh([slot]);
    });
    return () => {
      googletag.cmd.push(async function () {
        if (slot) {
          await googletag.destroySlots([slot]);
        }
      });
    };
  }, [
    context?.adCategories && Array.isArray(context.adCategories)
      ? context.adCategories.join(',')
      : null,
    router.asPath,
  ]);
};

export default useAds;
