import React from 'react';
import PropTypes from 'prop-types';

const IconScales = (props) => {
  return (
    <img
      src="icons/scales@2x.png"
      alt="Scales of Justice"
      className={props.elementClass}
    />
  );
};

IconScales.propTypes = {
  elementClass: PropTypes.string,
};

export default IconScales;
