import React, { FC } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  title?: string;
  className?: string;
}

const IconPerson: FC<Props> = ({
  width = '16',
  height = '16',
  color = 'var(--color-white)',
  title = '',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={color}
      fill="transparent"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.6218 13.2069C12.196 12.2036 11.2018 11.5 10.0432 11.5H5.84324C4.68468 11.5 3.69048 12.2036 3.26465 13.2069M5.14324 6.25C5.14324 7.7964 6.39684 9.05 7.94324 9.05C9.48963 9.05 10.7432 7.7964 10.7432 6.25C10.7432 4.7036 9.48963 3.45 7.94324 3.45C6.39684 3.45 5.14324 4.7036 5.14324 6.25ZM0.943238 8C0.943238 11.866 4.07724 15 7.94324 15C11.8092 15 14.9432 11.866 14.9432 8C14.9432 4.13401 11.8092 1 7.94324 1C4.07724 1 0.943238 4.13401 0.943238 8Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {title && <title>{title}</title>}
    </svg>
  );
};

export default IconPerson;
