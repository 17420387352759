import React, { FC } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  title?: string;
  className?: string;
}

const IconPodcast: FC<Props> = ({
  width = '15',
  height = '17',
  color = 'var(--color-white)',
  title = 'Podcasts',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={color}
      fill="transparent"
      viewBox="0 0 15 17"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.1259 12.7498C12.7858 11.6272 13.875 9.75919 13.875 7.63918C13.875 4.20259 11.0207 1.4165 7.5 1.4165C3.97928 1.4165 1.125 4.20259 1.125 7.63918C1.125 9.75919 2.21421 11.6272 3.87413 12.7498M4.92143 9.9165C4.32578 9.31444 3.95833 8.50596 3.95833 7.6148C3.95833 5.75654 5.54415 4.24984 7.5 4.24984C9.45585 4.24984 11.0417 5.75654 11.0417 7.6148C11.0417 8.50657 10.6742 9.31444 10.0786 9.9165M7.5 15.5832C6.7176 15.5832 6.08333 14.9489 6.08333 14.1665V12.7498C6.08333 11.9674 6.7176 11.3332 7.5 11.3332C8.2824 11.3332 8.91667 11.9674 8.91667 12.7498V14.1665C8.91667 14.9489 8.2824 15.5832 7.5 15.5832ZM8.20833 7.7915C8.20833 8.18271 7.8912 8.49984 7.5 8.49984C7.1088 8.49984 6.79167 8.18271 6.79167 7.7915C6.79167 7.4003 7.1088 7.08317 7.5 7.08317C7.8912 7.08317 8.20833 7.4003 8.20833 7.7915Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {title && <title>{title}</title>}
    </svg>
  );
};

export default IconPodcast;
