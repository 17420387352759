'use client';
import React, { FunctionComponent, MouseEvent, useRef, useState } from 'react';
import Link from 'next/link';
import sty from './styles/Auth.module.css';
import IconCloseModal from 'components/Icons/IconClose';

interface Props {
  username?: string;
  onClose: (path: string) => void;
}

const ResetPasswordForm: FunctionComponent<Props> = ({ username, onClose }) => {
  const unameRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [message, setMessage] = useState<undefined | string>(undefined);

  async function resetPasswordHandler(e: MouseEvent) {
    e.preventDefault();
    const response = await fetch(
      `${process.env.NEXTAUTH_URL || ''}/api/auth/reset-password`,
      {
        method: 'POST',
        credentials: 'same-origin',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: unameRef?.current?.value,
        }),
      }
    );
    const result = await response.json();
    console.log('reset password result: ', result);
    setIsCodeSent(true);
    setMessage('Check your email for the verification code.');
  }

  async function changePasswordHandler(e: MouseEvent) {
    e.preventDefault();
    const response = await fetch(
      `${process.env.NEXTAUTH_URL || ''}/api/auth/change-password`,
      {
        method: 'POST',
        credentials: 'same-origin',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: unameRef?.current?.value,
          verificationCode: codeRef?.current?.value,
          newPassword: passRef?.current?.value,
        }),
      }
    );
    const result = await response.json();
    console.log('change password result: ', result);
    setMessage('Your password has been updated.');
  }

  return (
    <div className={`${sty.modal} grid grid-2col has-basic-links`}>
      <div className={sty.modalContainerLeft_resetPassword}>
        <div className={sty.modalOverlay}>
          <div className={sty.modalInnerWrapper}>
            <img
              className={sty.modalOverlayImageOne}
              src={'/img/column-of-colored-triangles3.svg'}
              alt="" /* decorative */
            />
            <div className={`${sty.modalOverlayText} hdg-1`}>
              <div>Welcome back </div>
              <div>
                to news <div className={sty.modalForYou}>for you</div>
              </div>
            </div>
            <div className={sty.modalOverlayImageTwoOuter}>
              <img
                className={sty.modalOverlayImageTwo}
                src={'/img/column-of-colored-triangles4.svg'}
                alt="" /* decorative */
              />
            </div>
          </div>
        </div>
      </div>
      <div className={sty.modalContainerRight}>
        <div className={sty.topContainer}>
          <div className="hdg-2">Reset Password</div>
          <button
            onClick={() => onClose('signin')}
            className={sty.closeButton}
            aria-label="Close"
          >
            <IconCloseModal color="var(--color-mpr-charcoal)" />
          </button>
        </div>
        {!isCodeSent && (
          <form>
            <p style={{ marginBottom: '1rem' }}>
              Enter the username on file and we&apos;ll send you a code to reset
              your password.
            </p>
            <label
              className={`${sty.lbl} ${sty.block} type-sm`}
              htmlFor="username"
            >
              Username
            </label>
            <input
              className={`${sty.inpt} ${sty.largeInput}`}
              ref={unameRef}
              type="text"
              name="username"
              value={username}
            />
            <div className={`${sty.container} ${sty.containerInner}`}>
              {' '}
              <button
                className="btn btn-primary btn-slim btn-full-width"
                type="button"
                onClick={resetPasswordHandler}
              >
                submit request
              </button>
            </div>
          </form>
        )}
        {isCodeSent && (
          <form>
            <label
              className={`${sty.lbl} ${sty.block} type-sm`}
              htmlFor="username"
            >
              Username
            </label>
            <input
              className={`${sty.inpt} ${sty.largeInput}`}
              ref={unameRef}
              type="text"
              name="username"
            />
            <div className={`${sty.container} ${sty.containerInner}`}>
              <div>
                <label
                  htmlFor="verificationCode"
                  className={`${sty.lbl} ${sty.block} type-sm`}
                >
                  Verification Code{' '}
                </label>
                <input
                  className={sty.inpt}
                  ref={codeRef}
                  type="text"
                  name="verificationCode"
                />
              </div>
              <div>
                <label htmlFor="newPassword" className={`${sty.lbl} type-sm`}>
                  New Password{' '}
                </label>
                <input
                  className={sty.inpt}
                  ref={passRef}
                  type="password"
                  name="newPassword"
                />
              </div>
            </div>
            <p style={{ marginTop: '1rem' }} className="type-sm">
              Password must be at least 8 characters and contain one uppercase
              letter, one lowercase letter, one number and one symbol
            </p>
            <div className={`${sty.container} ${sty.containerInner}`}>
              <button
                className="btn btn-primary btn-full-width btn-slim"
                type="button"
                onClick={changePasswordHandler}
              >
                Change Password
              </button>
            </div>
          </form>
        )}
        <div className={`${sty.supplementalTextBottom} type-sm`}>
          <b>Still require assistance or not sure what your User Name is?</b>
          <div>
            <Link
              className="link"
              href="https://www.mpr.org/contact"
              target="_blank"
            >
              Contact us
            </Link>
          </div>{' '}
        </div>
        {message && <div className={sty.supplementalText}>{message}</div>}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
