import React, { FC } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  title?: string;
  className?: string;
}

const IconSearch: FC<Props> = ({
  width = '17',
  height = '16',
  color = 'var(--color-white)',
  title = 'Search',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M15.6362 13.5267L13.3415 11.2309C14.1403 10.0243 14.5402 8.63013 14.5398 7.24025C14.5408 5.39045 13.832 3.53242 12.4198 2.12098C11.0084 0.708996 9.15088 -0.00113339 7.30165 1.35777e-06C5.45167 -0.00111508 3.59439 0.708996 2.18313 2.1208C0.770763 3.53242 0.0612188 5.39009 0.0617679 7.24025C0.0612005 9.09004 0.77058 10.9481 2.18313 12.3588C3.59455 13.7702 5.45222 14.4786 7.30165 14.4782C8.69153 14.4786 10.0861 14.0781 11.2927 13.2795L13.5868 15.5757C14.1526 16.1414 15.0698 16.1414 15.6358 15.5761C16.2017 15.0099 16.2017 14.0931 15.6362 13.5267ZM11.2327 11.1717C10.1447 12.2577 8.72712 12.7977 7.30165 12.7992C5.87597 12.7977 4.45855 12.2577 3.37061 11.1717C2.28266 10.083 1.74216 8.66572 1.74102 7.24025C1.74214 5.81477 2.28284 4.39658 3.37061 3.30845C4.45893 2.22051 5.87579 1.68056 7.30165 1.67926C8.72676 1.68076 10.144 2.22069 11.2327 3.30845C12.3191 4.39678 12.86 5.81439 12.8606 7.24025C12.8598 8.66572 12.3192 10.0837 11.2327 11.1717Z" />
      {title && <title>{title}</title>}
    </svg>
  );
};

export default IconSearch;
