import { gql } from 'graphql-request';

const QUERY = gql`
  query AdWhitelist($slug: String!) {
    adWhitelist: potlatch(slug: $slug) {
      json
    }
  }
`;

export default QUERY;
