/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
'use client';
import React, { FC, MouseEventHandler, useRef, useState } from 'react';
import { signIn } from 'next-auth/react';
import sty from './styles/Auth.module.css';
import IconCloseModal from 'components/Icons/IconClose';
interface Props {
  onClose: (path: string) => void;
  newUserHandler: MouseEventHandler<HTMLAnchorElement>;
  forgotPasswordHandler: MouseEventHandler<HTMLAnchorElement>;
  callbackUrl?: string;
  redirect?: boolean;
}

const SignIn: FC<Props> = ({
  onClose,
  newUserHandler,
  forgotPasswordHandler,
  callbackUrl = '/',
  redirect,
}) => {
  const unameRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isIncorrectCred, setIncorrectCred] = useState(false);

  return (
    <div className={`${sty.modal} grid grid-2col has-basic-links`}>
      <div className={sty.modalContainerLeft_signIn}>
        <div className={sty.modalOverlay}>
          <div className={sty.modalInnerWrapper}>
            <img
              className={sty.modalOverlayImageOne}
              src={'/img/column-of-colored-triangles3.svg'}
              alt="" /* decorative */
            />
            <div className={`${sty.modalOverlayText} hdg-1`}>
              <div>Welcome back </div>
              <div>
                to news <span className={sty.modalForYou}>for you</span>
              </div>
            </div>
            <div className={sty.modalOverlayImageTwoOuter}>
              <img
                className={sty.modalOverlayImageTwo}
                src={'/img/column-of-colored-triangles4.svg'}
                alt="" /* decorative */
              />
            </div>
          </div>
        </div>
      </div>
      <div className={sty.modalContainerRight}>
        <div className={sty.topContainer}>
          <div className="hdg-2">Sign in to your profile</div>
          <button
            onClick={() => onClose('signin')}
            className={sty.closeButton}
            aria-label="Close"
          >
            <IconCloseModal color="var(--color-mpr-charcoal)" />
          </button>
        </div>
        {isIncorrectCred === true && (
          <div className={sty.incorrectCredentials}>
            Your username or password is invalid
          </div>
        )}
        <form>
          <label
            className={`${sty.lbl} ${sty.block} type-sm`}
            htmlFor="username"
          >
            Username
          </label>
          <input
            className={`${sty.inpt} ${sty.largeInput} input-thin`}
            ref={unameRef}
            type="text"
            name="username"
            id="username"
          />
          <label
            className={`${sty.lbl} ${sty.block} type-sm`}
            htmlFor="password"
          >
            Password
          </label>
          <input
            className={`${sty.inpt} ${sty.largeInput} input-thin`}
            ref={passRef}
            type="password"
            name="password"
            id="password"
          />
          <div className={`${sty.supplementalText} type-sm`}>
            <a onClick={forgotPasswordHandler}>Forgot Password?</a>
          </div>

          <div className={`${sty.container} ${sty.containerInner}`}>
            <button
              className="btn btn-primary btn-slim btn-full-width"
              onClick={async (evt) => {
                evt.preventDefault();
                setIsLoading(true);
                signIn('credentials', {
                  redirect,
                  username: unameRef?.current?.value,
                  password: passRef?.current?.value,
                  callbackUrl,
                })
                  .then((data) => {
                    if (data?.error) {
                      console.error(data.error);
                    }
                    setIsLoading(false);
                    setIncorrectCred(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setIsLoading(false);
                    setIncorrectCred(true);
                  });
              }}
            >
              Sign in
            </button>
          </div>
          {isLoading && (
            <p style={{ marginTop: '1rem' }}>
              Signing in... this may take a moment.
            </p>
          )}
        </form>
        <div className={`${sty.supplementalTextBottom} type-sm`}>
          <b>New to MPR News?</b>
          <div>
            <a onClick={newUserHandler}>Sign up</a>
          </div>{' '}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
