import React from 'react';
import PropTypes from 'prop-types';

const IconNsj = (props) => {
  return (
    <svg
      width="70"
      height="66"
      className={`icon icon-nsj ${
        props.elementClass ? props.elementClass : ''
      }`}
      viewBox="0 0 70 66"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Star</title>
      <path
        fill="#1c9dbf"
        d="M35.6,0.4l7.9,24c0.1,0.2,0.3,0.4,0.6,0.4h25.4c0.6,0,0.8,0.7,0.3,1.1L49.2,40.6C49,40.8,48.9,41,49,41.3l7.9,24c0.2,0.5-0.4,1-0.9,0.7L35.3,51.1c-0.2-0.1-0.5-0.1-0.7,0L14.1,65.9c-0.5,0.3-1.1-0.1-0.9-0.7l7.9-24c0.1-0.2,0-0.5-0.2-0.7L0.2,25.8c-0.5-0.3-0.2-1.1,0.3-1.1H26c0.3,0,0.5-0.2,0.6-0.4l7.9-24C34.6-0.1,35.4-0.1,35.6,0.4z"
      />
    </svg>
  );
};

IconNsj.propTypes = {
  elementClass: PropTypes.string,
};

export default IconNsj;
