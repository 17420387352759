import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactAudioPlayerInner } from 'apm-react-audio-player';
import IconChevron from 'components/Icons/IconChevron';

const AudioPlayer = (props) => {
  const [playerOpen, setPlayerOpen] = useState(props.playerStartsOpen);
  const [playerInHeader, setPlayerInHeader] = useState(!props.neverInHeader);

  const playerClasses = classNames({
    'player-wrapper': true,
    'player-wrapper-fixed': !props.nonFixed,
    'player-open': playerOpen,
    'player-wrapper-header': !props.neverInHeader ? playerInHeader : false,
    'player-wrapper-live': props.isAudioLive,
  });

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (!props.neverInHeader) {
        setPlayerInHeader(() => window.scrollY < 75);
      }
    });
  }, [props.neverInHeader]);

  return (
    <div className={playerClasses}>
      <ReactAudioPlayerInner
        {...props}
        title={props.audioTitle ?? 'MPR News'}
        audioSrc={props.audioSource ?? ''}
        description={<span>{props.audioSubtitle ?? 'Live Stream'}</span>}
        audioPlayerRef={props.audioPlayerRef}
        progressBarRef={props.progressBarRef}
        playBtnClass="player-btn player-btn-playpause js-player-play"
        isLive={props.isAudioLive}
        toggleMute={props.toggleMute}
        isMuted={props.isMuted}
        volumeCtrl={props.volumeControl}
        changePlayerCurrentTime={props.changePlayerCurrentTime}
        rewindControl={props.rewindControl}
        forwardControl={props.forwardControl}
        customHtml={
          <>
            <div className="listen-audio-description">
              {props.audioDescription}
            </div>
            <div className="listen-now-listening-to type-2x">
              Now Listening To Livestream
            </div>
            <div className="player-toggle-container">
              <button
                className="player-btn player-toggle"
                onClick={() => {
                  setPlayerOpen(!playerOpen);
                }}
              >
                <IconChevron
                  color="var(--color-white)"
                  width="1.125rem"
                  height="0.625rem"
                  direction={playerOpen ? 'down' : 'up'}
                  title={playerOpen ? 'Close Player' : 'Open Player'}
                />
              </button>
            </div>
            {
              <div className="player-thumb-and-text">
                <div className="player-thumbnail">
                  <img
                    className="player-thumbnail"
                    src={
                      props.audioThumbnailSrc ||
                      '/img/mpr-news-logo-thumbnail.svg'
                    }
                    alt={props.audioThumbnailAlt || 'MPR News logo'}
                  />
                </div>
              </div>
            }
          </>
        }
      />
    </div>
  );
};

AudioPlayer.propTypes = {
  audioPlayerRef: PropTypes.object,
  progressBarRef: PropTypes.object,
  audioSource: PropTypes.string,
  audioTitle: PropTypes.string,
  audioDescription: PropTypes.string,
  audioSubtitle: PropTypes.string,
  // audioThumbnail: PropTypes.string,
  isAudioLive: PropTypes.bool,
  // isAudioPlaying: PropTypes.bool,
  isPlayerVisible: PropTypes.bool,
  // handleAudioButtonClick: PropTypes.func,
  // loadPlayer: PropTypes.func,
  // hasVolumeControl: PropTypes.bool,
  rewindControl: PropTypes.func,
  forwardControl: PropTypes.func,
  loadPlayer: PropTypes.func,
  nonFixed: PropTypes.bool,
  audioThumbnailSrc: PropTypes.string,
  audioThumbnailAlt: PropTypes.string,
  toggleMute: PropTypes.func,
  isMuted: PropTypes.bool,
  volumeControl: PropTypes.func,
  changePlayerCurrentTime: PropTypes.func,
  neverInHeader: PropTypes.bool,
  playerStartsOpen: PropTypes.bool,
};

export default AudioPlayer;
