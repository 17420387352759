import React from 'react';
import useAds from '../../hooks/useAds';
import withAdSettings from './withAdSettings';
import PropTypes from 'prop-types';
import { Link } from '@apmg/titan';

const AdvertisementBanner = ({
  id = 'story_ad',
  sizeMap = {
    0: [320, 28],
    1: [320, 50],
    1024: [728, 90],
  },
  sizes = [
    [320, 28],
    [320, 50],
    [728, 90],
  ],
  cmsId,
  primaryCollection,
  collections = [],
  position = 'atf',
  isSidebar,
  customCss,
}) => {
  let adSize = sizes[0];
  if (process.browser) {
    const screenWidth = window.innerWidth;
    sizes.forEach((size) => {
      if (screenWidth >= size[0]) {
        adSize = size;
      }
    });
  }
  useAds({
    id,
    sizes: adSize,
    sizeMap,
    cmsId,
    primaryCollection,
    collections,
    position,
  });

  const wrapperId = isSidebar
    ? 'mpr-mr-ads'
    : 'not-sidebar-mpr-mr-ads' + Math.round(Math.random() * 100);

  return (
    <div id={wrapperId} className="ad">
      <div id={id} className={`ad_slot ad_slot-mr ${customCss}`}></div>
      {isSidebar && (
        <div className="ad_textWrapper has-basic-links">
          <div className="link">
            <Link
              href="/page?slug=underwriting"
              as="/underwriting"
              className="ad_link"
            >
              Providing Support for MPR. Learn More
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

AdvertisementBanner.propTypes = {
  id: PropTypes.string,
  sizeMap: PropTypes.shape({
    0: PropTypes.arrayOf(PropTypes.number),
    1: PropTypes.arrayOf(PropTypes.number),
    1024: PropTypes.arrayOf(PropTypes.number),
  }),
  sizes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  cmsId: PropTypes.string,
  primaryCollection: PropTypes.string,
  collections: PropTypes.arrayOf(PropTypes.string),
  position: PropTypes.string,
  isSidebar: PropTypes.bool,
  customCss: PropTypes.string,
};

export default withAdSettings(AdvertisementBanner);
