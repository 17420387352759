import React, { FC } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  title?: string;
}

const IconPlay: FC<Props> = ({
  width = '15',
  height = '11',
  color = 'var(--color-white)',
  title = 'Menu',
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 15 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.833333 10.5H14.1667C14.625 10.5 15 10.125 15 9.66667C15 9.20833 14.625 8.83333 14.1667 8.83333H0.833333C0.375 8.83333 0 9.20833 0 9.66667C0 10.125 0.375 10.5 0.833333 10.5ZM0.833333 6.33333H14.1667C14.625 6.33333 15 5.95833 15 5.5C15 5.04167 14.625 4.66667 14.1667 4.66667H0.833333C0.375 4.66667 0 5.04167 0 5.5C0 5.95833 0.375 6.33333 0.833333 6.33333ZM0 1.33333C0 1.79167 0.375 2.16667 0.833333 2.16667H14.1667C14.625 2.16667 15 1.79167 15 1.33333C15 0.875 14.625 0.5 14.1667 0.5H0.833333C0.375 0.5 0 0.875 0 1.33333Z" />
      {title && <title>{title}</title>}
    </svg>
  );
};

export default IconPlay;
