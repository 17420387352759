import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconTikTok = (props) => {
  const classes = classNames({
    icon: true,
    'icon-tiktok': true,
    [props.elementClass]: props.elementClass,
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={`icon icon-mail ${
        props.elementClass ? props.elementClass : ''
      }`}
    >
      <title>TikTok</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9858 12C24.9858 18.6274 19.6132 24 12.9858 24C6.35846 24 0.98584 18.6274 0.98584 12C0.98584 5.37262 6.35846 0 12.9858 0C19.6132 0 24.9858 5.37262 24.9858 12Z
        M15.5074 9.44641C16.4927 10.1501 17.6992 10.5642 19.0023 10.5642V8.59114C18.2749 8.43634 17.631 8.05636 17.1468 7.52788C16.3181 7.01101 15.7209 6.15826 15.5459 5.16016H13.719V15.1717C13.7148 16.3388 12.7671 17.2838 11.5985 17.2838C10.9098 17.2838 10.298 16.9557 9.91051 16.4475C9.21859 16.0985 8.74411 15.3819 8.74411 14.5544C8.74411 13.3839 9.69334 12.435 10.8645 12.435C11.0889 12.435 11.3052 12.47 11.508 12.5343V10.5397C8.99296 10.5917 6.97021 12.6456 6.97021 15.1718C6.97021 16.4327 7.47394 17.5759 8.29141 18.4111C9.02896 18.9061 9.91681 19.1948 10.872 19.1948C13.432 19.1948 15.5074 17.1204 15.5074 14.5616V9.44641Z
        M19.0018 8.59196V8.05844C18.3458 8.05943 17.7028 7.87582 17.1463 7.52861C17.6389 8.06769 18.2876 8.43945 19.0018 8.59196ZM15.5454 5.16098C15.5286 5.0656 15.5158 4.96958 15.5069 4.87316V4.55078H12.9844V14.5624C12.9804 15.7294 12.0327 16.6744 10.864 16.6744C10.5208 16.6744 10.1969 16.5931 9.91 16.4483C10.2975 16.9566 10.9093 17.2846 11.598 17.2846C12.7665 17.2846 13.7143 16.3396 13.7184 15.1725V5.16098H15.5454ZM11.5076 10.5406V9.97256C11.2968 9.94374 11.0843 9.92934 10.8716 9.92945C8.31124 9.92945 6.23584 12.0038 6.23584 14.5624C6.23584 16.1665 7.05151 17.5803 8.29099 18.4119C7.47352 17.5767 6.96979 16.4335 6.96979 15.1725C6.96979 12.6465 8.99245 10.5925 11.5076 10.5406Z"
      />
    </svg>
  );
};

IconTikTok.propTypes = {
  elementClass: PropTypes.string,
};

export default IconTikTok;
