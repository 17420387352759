import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

const MainLayout = ({ children, alert }) => (
  <>
    <Header alert={alert} />
    <main className="main">{children}</main>
    <Footer />
  </>
);

MainLayout.propTypes = {
  children: PropTypes.any,
  alert: PropTypes.any,
};

export default MainLayout;
