import PropTypes from 'prop-types';

export const adSettingsPropType = PropTypes.shape({
  whitelist: PropTypes.arrayOf(PropTypes.string),
});

shouldShowAds.prototype = {
  slug: PropTypes.string,
  settings: adSettingsPropType,
};

export function shouldShowAds(slug, settings) {
  return !settings?.whitelist.includes(slug);
}
