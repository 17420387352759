import React from 'react';
import PropTypes from 'prop-types';

const IconBallotBoxColor = (props) => {
  return (
    <svg
      width="62"
      height="62"
      className={`icon icon-ballotBoxColor ${
        props.elementClass ? props.elementClass : ''
      }`}
      viewBox="0 0 62 62"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Ballot Box</title>
      <rect fill="#ed1c24" x="4.97" y="51.09" width="52.05" height="4.21" />
      <rect fill="#ed1c24" x="4.97" y="44.16" width="52.05" height="4.21" />
      <rect fill="#ed1c24" x="4.97" y="37.23" width="52.05" height="4.21" />
      <polygon
        fill="#023450"
        points="4.97 33.97 13.11 21.1 48.8 21.1 57.03 33.97 4.97 33.97"
      />
      <polygon
        fill="#fff"
        points="47.13 28.95 14.66 28.95 15.97 26.37 45.81 26.37 47.13 28.95"
      />
      <rect fill="#00bfed" x="17.49" y="6.69" width="27.25" height="22.25" />
      <polygon
        fill="#fff"
        points="29.02 24.95 23.38 17.82 25.63 16.04 29.09 20.41 36.65 11.46 38.84 13.31 29.02 24.95"
      />
    </svg>
  );
};

IconBallotBoxColor.propTypes = {
  elementClass: PropTypes.string,
};

export default IconBallotBoxColor;
