import React, { FC } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  title?: string;
}

const IconPlay: FC<Props> = ({
  width = '1.25rem',
  height = '1.25rem',
  color = 'var(--color-white)',
  title = 'Play',
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 10.1 12.4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0,1v10.4c0,0.8,0.9,1.3,1.5,0.8L9.7,7c0.6-0.4,0.6-1.3,0-1.7L1.5,0.2C0.9-0.3,0,0.2,0,1z" />
      {title && <title>{title}</title>}
    </svg>
  );
};

export default IconPlay;
