import React, { FC } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  title?: string;
  className?: string;
}

const IconMicrophone: FC<Props> = ({
  width = '17',
  height = '17',
  color = 'var(--color-white)',
  title = 'Give',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={color}
      fill="transparent"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.25008 14.2285H6.09904C6.34011 14.2285 6.57971 14.2572 6.81341 14.3146L8.76705 14.7893C9.19095 14.8926 9.63255 14.9026 10.0609 14.8195L12.2209 14.3992C12.7915 14.2881 13.3165 14.0148 13.7278 13.6147L15.2561 12.128C15.6925 11.7042 15.6925 11.0164 15.2561 10.5919C14.8631 10.2096 14.2409 10.1666 13.7964 10.4908L12.0153 11.7902C11.7602 11.9767 11.4498 12.0771 11.1306 12.0771H9.41064L10.5054 12.077C11.1225 12.077 11.6223 11.5908 11.6223 10.9906V10.7733C11.6223 10.2749 11.2736 9.84027 10.7767 9.71979L9.087 9.30886C8.81202 9.24217 8.5304 9.20846 8.24731 9.20846C7.5639 9.20846 6.32684 9.77429 6.32684 9.77429L4.25008 10.6428M1.41675 10.3418L1.41675 14.4501C1.41675 14.8468 1.41675 15.0452 1.49395 15.1967C1.56186 15.33 1.67022 15.4383 1.8035 15.5063C1.95503 15.5835 2.15338 15.5835 2.55008 15.5835H3.11675C3.51345 15.5835 3.7118 15.5835 3.86332 15.5063C3.99661 15.4383 4.10497 15.33 4.17288 15.1967C4.25008 15.0452 4.25008 14.8468 4.25008 14.4501V10.3418C4.25008 9.94509 4.25008 9.74674 4.17288 9.59522C4.10497 9.46194 3.99661 9.35358 3.86332 9.28567C3.7118 9.20846 3.51345 9.20846 3.11675 9.20846H2.55008C2.15338 9.20846 1.95503 9.20846 1.8035 9.28567C1.67022 9.35358 1.56186 9.46194 1.49395 9.59522C1.41675 9.74674 1.41675 9.94509 1.41675 10.3418ZM12.1773 2.54464C11.7546 1.66004 10.7799 1.19139 9.83206 1.64373C8.8842 2.09606 8.48039 3.16877 8.87724 4.11046C9.1225 4.69245 9.82519 5.82263 10.3262 6.60111C10.5114 6.88875 10.6039 7.03257 10.7391 7.1167C10.8551 7.18887 11.0003 7.22777 11.1368 7.22325C11.2959 7.21799 11.448 7.13972 11.7521 6.98318C12.5753 6.55952 13.7489 5.9321 14.2523 5.55072C15.0669 4.93361 15.2686 3.79933 14.6588 2.93705C14.049 2.07477 12.9857 1.98992 12.1773 2.54464Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {title && <title>{title}</title>}
    </svg>
  );
};

export default IconMicrophone;
