import React, { useEffect, useState } from 'react';
import { useAudioPlayer } from 'apm-react-audio-player';
import NowPlayingClient from 'nowplaying-client-2';
import PropTypes from 'prop-types';

export const defaultAudioState = {
  audioPlayerRef: undefined,
  progressBarRef: undefined,
  audioSource: '',
  audioTitle: 'MPR News',
  audioDescription: '',
  nowPlayingTitle: 'MPR News',
  audioThumbnail: null,
  audioSubtitle: 'Live Stream',
  isAudioPlaying: false,
  isPlayerVisible: false,
  isAudioLive: true,
  nowPlayingThumbnail: null,
  playerInstance: null,
  playlist: {},
  audioThumbnailSrc: '/img/mpr-news-logo-thumbnail.svg',
  audioThumbnailAlt: 'MPR News logo',
  // handleAudioButtonClick: (audioSource, audioTitle, audioSubtitle) => undefined,
  // loadPlayer: () => undefined,
  setAudio: (source, title, subTitle, audioThumbnailSrc, audioThumbnailAlt) =>
    undefined,
  resetLivePlayer: () => undefined,
  togglePlaying: () => undefined,
  play: () => undefined,
  pause: () => undefined,
  toggleMute: () => undefined,
  volumeControl: () => undefined,
  rewindControl: () => undefined,
  forwardControl: () => undefined,
};

const AudioPlayerContext = React.createContext(defaultAudioState);

export const AudioPlayerProvider = ({ children }) => {
  const [state, setState] = useState({
    ...defaultAudioState,
  });
  const audioPlayerRef = React.useRef();
  const progressBarRef = React.useRef();
  const fakeRef = React.useRef();

  const {
    onLoadedMetadata,
    calculateTime,
    volumeControl,
    togglePlaying,
    toggleMute,
    isMuted,
    changePlayerCurrentTime,
    play,
    pause,
    isPlaying,
    isFinishedPlaying,
    currentTime,
    duration,
    formatCalculateTime,
    rewindControl,
    forwardControl,
    audioThumbnailSrc = state.audioThumbnailSrc,
    audioThumbnailAlt = state.audioThumbnailAlt,
  } = useAudioPlayer(audioPlayerRef, progressBarRef);

  useEffect(() => {
    if (isFinishedPlaying) {
      setAudio(
        defaultAudioState.audioSource,
        defaultAudioState.audioTitle,
        defaultAudioState.audioSubtitle
      );
    }
  }, [isFinishedPlaying]);

  useEffect(() => {
    if (state.isAudioLive) {
      const client = new NowPlayingClient({
        server: 'wss://nowplayingv2.publicradio.org',
      });

      const scheduleRegistration = client.register_callback(
        'mpr-news',
        'schedule',
        (data) => {
          if (state.isAudioLive && data?.schedule) {
            setNowPlayingTitle(data.schedule);
          }
        }
      );

      return () => {
        scheduleRegistration.unregister();
      };
    }
  }, [state.isAudioLive]);

  useEffect(() => {
    if (!fakeRef.current) {
      fakeRef.current = true;
      return;
    }
    if (state.audioSource == '') {
      setState((prevState) => {
        return {
          ...prevState,
          audioSource: 'https://nis.stream.publicradio.org/nis.aac',
        };
      });
    }
  }, [state]);

  const setNowPlayingTitle = (schedule) => {
    let nowPlayingTitle = schedule[0].shows[0].name;
    let nowPlayingHost = schedule[0].people[0];
    if (schedule[0].people[0]) {
      nowPlayingTitle += ` with ${schedule[0].people[0].name}`;
    }
    setState((prevState) => {
      return {
        ...prevState,
        nowPlayingTitle,
        audioTitle: nowPlayingTitle,
        audioThumbnailSrc:
          nowPlayingHost?.image?.aspect_ratios?.square.instances[0].url,
        audioThumbnailAlt: nowPlayingHost?.image?.alt_text,
      };
    });
  };

  const setAudio = (
    audioSource,
    audioTitle = defaultAudioState.audioTitle,
    audioSubtitle = defaultAudioState.audioSubtitle,
    audioThumbnailSrc = defaultAudioState.audioThumbnailSrc,
    audioThumbnailAlt = defaultAudioState.audioThumbnailAlt
  ) => {
    setState((prevState) => {
      return {
        ...prevState,
        audioSource,
        audioTitle,
        audioSubtitle,
        isAudioLive: audioSource == defaultAudioState.audioSource,
        audioThumbnailAlt,
        audioThumbnailSrc,
      };
    });
  };

  return (
    <AudioPlayerContext.Provider
      value={{
        ...state,
        isAudioPlaying: isPlaying,
        setAudio,
        onLoadedMetadata: () => {
          onLoadedMetadata();
          if (!state.isAudioLive) {
            play();
          }
        },
        calculateTime,
        formatCalculateTime,
        volumeControl,
        togglePlaying,
        toggleMute,
        rewindControl,
        forwardControl,
        isMuted,
        changePlayerCurrentTime,
        isPlaying,
        isFinishedPlaying,
        currentTime,
        duration,
        audioPlayerRef,
        progressBarRef,
        audioThumbnailSrc,
        audioThumbnailAlt,
        play,
        pause,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerContext;

AudioPlayerProvider.propTypes = {
  children: PropTypes.node,
};
