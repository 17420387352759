import React, { FC, useContext } from 'react';
import Alert from './Alert';
import { PotlatchNotificationContext } from 'context/PotlatchNotificationContext';

interface Props {
  resourceType: string;
}

const AlertWrapper: FC<Props> = ({ resourceType }) => {
  const context = useContext(PotlatchNotificationContext);

  if (context?.info.show_on.includes(resourceType)) {
    return <Alert {...context} />;
  }
  return null;
};

export default AlertWrapper;
