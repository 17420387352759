'use client';
import React from 'react';
import ResetPasswordForm from './ResetPasswordForm';
import IconCloseModal from 'components/Icons/IconClose';
import sty from './styles/Auth.module.css';

interface Props {
  onClose: (path: string) => void;
}

const ResetPassword = (props: Props) => {
  return (
    <div className={sty.container}>
      <div>
        <button
          onClick={() => props.onClose('forgot-password')}
          className={sty.closeButton}
          aria-label="Close"
        >
          <IconCloseModal />
        </button>

        <ResetPasswordForm onClose={() => props.onClose('forgot-password')} />
      </div>
    </div>
  );
};

export default ResetPassword;
