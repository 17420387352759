import { useSession } from 'next-auth/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export interface Favorite {
  title: string;
  slug: string;
  contentArea: string;
  resourceType: string;
  id: string;
  cmsId: string;
  sk: string;
  album?: string;
  artist?: string;
  duration?: string;
  art_url?: string;
  createDate?: string;
}

const useFavorites = (
  resourceType?: string
): [
  Map<string, Favorite>,
  () => Promise<Map<string, Favorite> | undefined>,
  (id: string) => Promise<void>,
  boolean,
  Dispatch<SetStateAction<boolean>>
] => {
  const sessionData = useSession();
  const session = sessionData?.data;
  const [favorites, setFavorites] = useState<Map<string, Favorite>>(new Map());
  const [isLoading, setIsLoading] = useState(true);

  const fetchFavorites = async () => {
    const url = resourceType
      ? `/api/data/favorite/${resourceType}`
      : '/api/data/favorite';
    const response = await fetch(url, { cache: 'no-cache' });
    const data = await response.json();
    const favs = new Map<string, Favorite>(
      data.data?.map((favorite: Favorite) => [favorite.cmsId, favorite])
    );
    setFavorites(favs);
    setIsLoading(false);
    return favs;
  };

  const deleteFavorite = async (cmsId: string) => {
    try {
      await fetch(`/api/data/favorite/${resourceType}/${cmsId}`, {
        method: 'DELETE',
      });
      fetchFavorites();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (session) {
      fetchFavorites();
    }
  }, [resourceType, session]);

  return [favorites, fetchFavorites, deleteFavorite, isLoading, setIsLoading];
};

export default useFavorites;
