import React, { FC } from 'react';

interface Props {
  color?: string;
  elementClass?: string;
  width?: string;
  height?: string;
}

const IconChevronDown: FC<Props> = ({
  color = 'var(--color-mpr-dark-gray)',
  elementClass,
  width = '24px',
  height = '24px',
}) => (
  <svg
    className={`icon icon-chevronDown ${elementClass ? elementClass : ''}`}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Down</title>
    <path
      d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
      fill={color}
    />
    <path fill="none" d="M0 0h24v24H0V0z" />
  </svg>
);

export default IconChevronDown;
