import React, { FC } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  title?: string;
  className?: string;
}

const IconMicrophone: FC<Props> = ({
  width = '13',
  height = '15',
  color = 'var(--color-white)',
  title = '',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={color}
      fill="transparent"
      viewBox="0 0 13 15"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.1666 7.5V8.20833C12.1666 11.3379 9.62953 13.875 6.49992 13.875C3.37031 13.875 0.833252 11.3379 0.833252 8.20833V7.5M6.49992 11.0417C4.93511 11.0417 3.66659 9.77314 3.66659 8.20833V3.95833C3.66659 2.39353 4.93511 1.125 6.49992 1.125C8.06473 1.125 9.33325 2.39353 9.33325 3.95833V8.20833C9.33325 9.77314 8.06473 11.0417 6.49992 11.0417Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {title && <title>{title}</title>}
    </svg>
  );
};

export default IconMicrophone;
