import { useRouter } from 'next/compat/router';
import React, { useContext } from 'react';
import { shouldShowAds } from '../../config/adSettings';
import { SiteContext } from '../../context/AdvertisementContext';

function withAdSettings(AdComponent) {
  const Advertisement = (props) => {
    const router = useRouter();
    const context = useContext(SiteContext);
    return shouldShowAds(router?.asPath, context.adSettings) ? (
      <AdComponent {...props} />
    ) : null;
  };

  return Advertisement;
}

export default withAdSettings;
