import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AudioPlayerContext from 'context/AudioPlayerContext';
import AudioPlayer from './AudioPlayer';

const AudioWrapper = (props) => {
  const context = useContext(AudioPlayerContext);

  return (
    <AudioPlayer
      audioPlayerRef={context.audioPlayerRef}
      progressBarRef={context.progressBarRef}
      audioSource={context.audioSource}
      audioSubtitle={context.audioSubtitle}
      audioTitle={context.audioTitle}
      audioDescription={context.audioDescription}
      audioThumbnailSrc={context.audioThumbnailSrc}
      audioThumbnailAlt={context.audioThumbnailAlt}
      isAudioLive={context.isAudioLive}
      isAudioPlaying={context.isAudioPlaying}
      isPlayerVisible={context.isPlayerVisible}
      // handleAudioButtonClick={context.handleAudioButtonClick}
      // loadPlayer={context.loadPlayer}
      // playerInstance={context.playerInstance}
      hasVolumeControl={props.hasVolumeControl}
      nonFixed={props.nonFixed}
      onLoadedMetadata={context.onLoadedMetadata}
      calculateTime={context.calculateTime}
      togglePlaying={context.togglePlaying}
      toggleMute={context.toggleMute}
      isMuted={context.isMuted}
      volumeControl={context.volumeControl}
      changePlayerCurrentTime={context.changePlayerCurrentTime}
      isPlaying={context.isPlaying}
      isFinishedPlaying={context.isFinishedPlaying}
      currentTime={context.currentTime}
      duration={context.duration}
      formatCalculateTime={context.formatCalculateTime}
      rewindControl={context.rewindControl}
      forwardControl={context.forwardControl}
      neverInHeader={props.neverInHeader}
      playerStartsOpen={props.playerStartsOpen}
    />
  );
};

AudioWrapper.propTypes = {
  hasVolumeControl: PropTypes.bool,
  nonFixed: PropTypes.bool,
  neverInHeader: PropTypes.bool,
  playerStartsOpen: PropTypes.bool,
};

export default AudioWrapper;
