import React from 'react';
import Link from 'next/link';
import IconPerson from 'components/Icons/IconPerson';

const ListenHeader = () => (
  <header className="header listen-header">
    <div className="wrapper header-contents">
      <Link href="/" passHref>
        <picture>
          <source srcSet="/img/MPR-news-logo.svg" media="(min-width: 48em)" />
          <img
            src="/img/mpr-news-logo-thumbnail.svg"
            className="header-logo"
            alt="MPR News"
          />
        </picture>
        <div className="header-tagline">Stay Curious. Stay Connected.</div>
      </Link>
      <div className="listen-header-links">
        <Link
          href="https://support.mpr.org/news-web"
          passHref
          className="btn btn-primary btn-slim small-gap"
          target="_blank"
          aria-label="Donate Now - Link opens in a new tab"
        >
          <span className="type-lg icon-heart">♡</span>Donate Now
        </Link>
        <a
          className="listen-large-screen-only"
          href="https://support.mpr.org/news-web"
        >
          <IconPerson title="" /* decorative */ />
          Become a Member
        </a>
      </div>
    </div>
    <a
      className="listen-small-screen-only"
      href="https://support.mpr.org/news-web"
    >
      <IconPerson title="" /* decorative */ />
      Become a Member
    </a>
  </header>
);

export default ListenHeader;
