import React from 'react';
import PropTypes from 'prop-types';
import ListenHeader from '../components/ListenHeader/ListenHeader';
import ListenFooter from '../components/ListenFooter/ListenFooter';
import AudioWrapper from 'components/AudioPlayer';
import AdvertisementBanner from 'components/Advertisement/AdvertisementBanner';

const ListenLayout = ({ children }) => (
  <div className="listenLayout container">
    <ListenHeader />
    <div className="listen-banner-ad">
      <AdvertisementBanner id="mpr-ad-1" />
    </div>
    <main className="main main-listen">
      <AudioWrapper neverInHeader={true} playerStartsOpen={true} />
      {children}
    </main>
    <div className="listen-banner-ad">
      <AdvertisementBanner id="mpr-ad-3" />
    </div>
    <ListenFooter />
  </div>
);

ListenLayout.propTypes = {
  children: PropTypes.any,
};

export default ListenLayout;
