import React, { FC } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  title?: string;
}

const IconPlay: FC<Props> = ({
  width = '1.25rem',
  height = '1.25rem',
  color = 'var(--color-white)',
  title = 'Pause',
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 19.9 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.227783" width="7.51219" height="22" rx="3.7561" />
      <rect x="12.3411" y="0.227783" width="7.51219" height="22" rx="3.7561" />
      {title && <title>{title}</title>}
    </svg>
  );
};

export default IconPlay;
