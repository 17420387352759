'use client';
import React, { FunctionComponent, useEffect } from 'react';
import useFavorites, { Favorite } from 'hooks/useFavorites';
import { useSession } from 'next-auth/react';

interface FavoritesState {
  favorites?: Map<string, Favorite>;
  fetchFavorites: () => Promise<Map<string, Favorite> | undefined>;
}

type Props = {
  children: React.ReactNode;
};

const initialSiteState: FavoritesState = {
  favorites: undefined,
  fetchFavorites: async () => {
    return undefined;
  },
};

export const FavoritesContext =
  React.createContext<FavoritesState>(initialSiteState);

export const FavoritesContextProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const [favorites, fetchFavorites] = useFavorites();
  const sessionData = useSession();
  const status = sessionData?.status;

  useEffect(() => {
    if (status === 'authenticated') {
      fetchFavorites();
    }
  }, [status]);

  return (
    <FavoritesContext.Provider
      value={{
        ...initialSiteState,
        favorites,
        fetchFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
