import React, { FC } from 'react';

interface Props {
  color?: string;
  elementClass?: string;
}

const IconChevronRight: FC<Props> = ({
  color = 'var(--color-mpr-dark-gray)',
  elementClass,
}) => {
  return (
    <svg
      className={`icon icon-chevronRight ${elementClass ? elementClass : ''}`}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Right</title>
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill={color} />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

export default IconChevronRight;
