import React from 'react';
import { Link } from '@apmg/titan';

const ListenFooter = () => (
  <ul className="listen-footer-list">
    <li>
      <Link href="/schedule" className="link link-plain">
        Program Schedule
      </Link>
    </li>
    <li>
      <Link
        href="https://www.mpr.org/listen/stations"
        className="link link-plain"
      >
        Station Directory
      </Link>
    </li>
  </ul>
);

export default ListenFooter;
