import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@apmg/titan';
import Icon from '../Icons/Icon';

const FooterContact = (props) => {
  return (
    <div className="footer-social">
      <h3 className="hdg-5">Stay Connected to MPR News</h3>
      <ul className="footer-social-links">
        {props.links.map((link) => {
          return (
            <li key={link.href}>
              <Link href={link.href} className="link link-plain">
                {link.icon && <Icon name={link.icon} />}
                <span className="type-sm type-caps">{link.label}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FooterContact.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default FooterContact;
