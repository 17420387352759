import React from 'react';
import PropTypes from 'prop-types';
import DefaultHead from '../components/DefaultHead/DefaultHead';
import MainLayout from './MainLayout';
import HomeLayout from './HomeLayout';
import CardLayout from './CardLayout';
import NewspartnersLayout from './NewspartnersLayout';
import ListenLayout from './ListenLayout';
import AudioWrapper from 'components/AudioPlayer';
import AlertWrapper from 'components/Alert/AlertWrapper';

const Layout = (props) => {
  return (
    <>
      <DefaultHead />
      <LayoutInner layout={props.layout} resourceType={props.resourceType}>
        {props.children}
      </LayoutInner>
      {props.layout !== 'listen' && props.layout !== 'newspartners' && (
        <AudioWrapper />
      )}
    </>
  );
};

const LayoutInner = (props) => {
  if (props.layout === 'home') return <HomeLayout>{props.children}</HomeLayout>;

  if (props.layout === 'card') return <CardLayout>{props.children}</CardLayout>;

  if (props.layout === 'newspartners') {
    return <NewspartnersLayout>{props.children}</NewspartnersLayout>;
  }

  if (props.layout === 'listen') {
    return <ListenLayout>{props.children}</ListenLayout>;
  }

  return (
    <MainLayout alert={<AlertWrapper resourceType={props.resourceType} />}>
      {props.children}
    </MainLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  layout: PropTypes.string,
  resourceType: PropTypes.string,
};

LayoutInner.propTypes = {
  children: PropTypes.node,
  layout: PropTypes.string,
  resourceType: PropTypes.string,
};

export default Layout;
