import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconLinkedin = (props) => {
  const classes = classNames({
    icon: true,
    'icon-linkedin': true,
    [props.elementClass]: props.elementClass,
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={`icon icon-mail ${
        props.elementClass ? props.elementClass : ''
      }`}
    >
      <title>LinkedIn</title>
      <path d="M12.9715 0C14.6346 0 16.1925 0.315412 17.6453 0.946237C19.0981 1.57706 20.3694 2.4325 21.459 3.51254C22.5486 4.59259 23.4088 5.85902 24.0396 7.31183C24.6704 8.76464 24.9858 10.3226 24.9858 11.9857C24.9858 13.6487 24.6704 15.2067 24.0396 16.6595C23.4088 18.1123 22.5486 19.3835 21.459 20.4731C20.3694 21.5627 19.0981 22.4229 17.6453 23.0538C16.1925 23.6846 14.6346 24 12.9715 24C11.3084 24 9.75048 23.6846 8.29767 23.0538C6.84486 22.4229 5.57843 21.5627 4.49838 20.4731C3.41834 19.3835 2.5629 18.1123 1.93208 16.6595C1.30125 15.2067 0.98584 13.6487 0.98584 11.9857C0.98584 10.3226 1.30125 8.76464 1.93208 7.31183C2.5629 5.85902 3.41834 4.59259 4.49838 3.51254C5.57843 2.4325 6.84486 1.57706 8.29767 0.946237C9.75048 0.315412 11.3084 0 12.9715 0ZM9.2439 9.00358H6.23315V18.7527H9.2439V9.00358ZM7.72419 8.22939C8.14474 8.22939 8.50316 8.08602 8.79946 7.79928C9.09576 7.51254 9.2439 7.1589 9.2439 6.73835C9.2439 6.3178 9.09576 5.96416 8.79946 5.67742C8.50316 5.39068 8.14474 5.24731 7.72419 5.24731C7.30364 5.24731 6.95 5.39068 6.66326 5.67742C6.37652 5.96416 6.23315 6.3178 6.23315 6.73835C6.23315 7.1589 6.37652 7.51254 6.66326 7.79928C6.95 8.08602 7.30364 8.22939 7.72419 8.22939ZM19.7385 13.1039C19.7385 12.4158 19.5474 11.7419 19.1651 11.0824C18.7827 10.4229 18.2857 9.92115 17.674 9.57706C17.1197 9.27121 16.4745 9.10872 15.7385 9.08961C15.0026 9.07049 14.3287 9.19474 13.717 9.46237V9.00358H10.7349V18.7527H13.717V12.7885L14.9787 12.1864C15.1316 12.1099 15.3371 12.0717 15.5952 12.0717C15.8532 12.0717 16.0587 12.1195 16.2116 12.2151C16.3263 12.2724 16.441 12.3967 16.5557 12.5878C16.6704 12.779 16.7278 12.951 16.7278 13.1039V18.7527H19.7385V13.1039Z" />
    </svg>
  );
};

IconLinkedin.propTypes = {
  elementClass: PropTypes.string,
};

export default IconLinkedin;
