import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@apmg/titan';

const FooterLinks = (props) => {
  return (
    <div className="footer-links">
      <ul className="footer-link-list">
        {props.links.map((item) => (
          <li key={item.href} className="footer-link">
            <Link href={item.href}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

FooterLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, href: PropTypes.string })
  ),
};

export default FooterLinks;
