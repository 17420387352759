// const adUnitPath = '/22546217694/mpr.news' - old one;
const adUnitPath = '/22546217694/apmg/mpr/mpr-news';
export const globals = {
  hostnameProd: 'https://www.mprnews.org', // Used for meta tag url declarations
  siteDescription: "Minnesota's Online Source for News That Matters",
  siteName: 'MPR News',
  sizes: {
    collection: '(max-width: 1023px) 100vw, (min-width: 1024px) 50vw, 900px',
    primaryVisuals: '(max-width: 1100px) 100vw, 1100px',
  },
  adUnitPath: adUnitPath, // Used for ads url
};
