import React, { FC } from 'react';

interface Props {
  color?: string;
  className?: string;
  width?: string;
  height?: string;
  direction?: 'up' | 'down' | 'left' | 'right';
  title?: string;
  decorative?: boolean;
}

const IconChevron: FC<Props> = ({
  color = 'var(--color-mpr-dark-gray)',
  className,
  width = '12px',
  height = '7px',
  direction = 'down',
  title = direction,
  decorative = false,
}) => {
  function rotation(direction: string) {
    switch (direction) {
      case 'up':
        return '180deg';
      case 'down':
        return '0deg';
      case 'right':
        return '270deg';
      case 'left':
        return '90deg';
    }
  }

  return (
    <svg
      className={`icon icon-chevronDown ${className ? className : ''}`}
      width={width}
      height={height}
      viewBox="0 0 12 7"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      stroke={color}
      style={{ transform: `rotate(${rotation(direction)})` }}
      aria-hidden={decorative}
      aria-label={title}
      role="img"
    >
      <title>{title}</title>
      <path fill="none" strokeWidth="2" d="M1,1l5,5l5-5" />
    </svg>
  );
};

export default IconChevron;
