import React from 'react';
import PropTypes from 'prop-types';

const IconVirus = (props) => {
  return (
    <svg
      width="24"
      height="24"
      className={`icon icon-virus ${
        props.elementClass ? props.elementClass : ''
      }`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Virus</title>
      <path d="M19.4,14.74a2,2,0,0,0-1.22.43l-.88-.33a6.52,6.52,0,0,0,.1-1.1,6.26,6.26,0,0,0-.21-1.57l2.19-1a1,1,0,0,0-.84-1.82l-2.19,1a6.08,6.08,0,0,0-2.36-2l.89-2.63A1,1,0,0,0,13,5.06l-.92,2.72c-.22,0-.44,0-.67,0A6,6,0,0,0,9.75,8L8.48,5.22a1,1,0,1,0-1.82.84l.78,1.69h0a2,2,0,0,0-2,2,1.9,1.9,0,0,0,.17.79l-1.63-.6a1,1,0,0,0-.69,1.88l2.26.83a5.7,5.7,0,0,0-.11,1.1,5.78,5.78,0,0,0,.22,1.57l-2.19,1a1,1,0,0,0-.49,1.33,1,1,0,0,0,.91.58,1,1,0,0,0,.42-.09l2.18-1a6.28,6.28,0,0,0,1.36,1.41,2,2,0,0,0-.41,1.2,2,2,0,0,0,4,0,6.16,6.16,0,0,0,1.5-.19l.74,1.61a1,1,0,0,0,.91.58,1.06,1.06,0,0,0,.42-.09,1,1,0,0,0,.49-1.33l-.73-1.58a6.16,6.16,0,0,0,1.88-2l.82.31a2,2,0,1,0,2-2.29Zm-10-5a1,1,0,1,1-1,1A1,1,0,0,1,9.4,9.74Zm.5,7a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,9.9,16.74Z" />
    </svg>
  );
};

IconVirus.propTypes = {
  elementClass: PropTypes.string,
};

export default IconVirus;
