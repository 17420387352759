import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconUpdraft = (props) => {
  const classes = classNames({
    icon: true,
    'icon-updraft': true,
    [props.elementClass]: props.elementClass,
  });

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="46"
      height="42"
      viewBox="0 0 46 42"
    >
      <path
        d="M4.60156 23.9965C4.60156 24.1192 4.69356 24.1805 4.86223 24.1805H7.0549C7.16223 24.1805 7.25423 24.1039 7.36156 23.9659C7.6989 23.1379 8.23556 22.4479 8.97156 21.8959C9.69223 21.3592 10.5049 21.0525 11.4249 20.9759L12.2376 20.8685C12.4216 20.8685 12.5289 20.7765 12.5289 20.6079L12.6362 19.8105C12.8049 18.1545 13.4949 16.7745 14.7369 15.6552C15.9789 14.5359 17.4356 13.9839 19.1069 13.9839C20.7936 13.9839 22.2502 14.5359 23.4922 15.6399C24.7342 16.7439 25.4396 18.1392 25.6082 19.7952L25.7156 20.6845C25.7156 20.8685 25.8076 20.9605 25.9916 20.9605H28.4909C29.8709 20.9605 31.0516 21.4359 32.0176 22.4019C32.9836 23.3679 33.4742 24.5332 33.4742 25.8825C33.4742 27.2472 32.9836 28.4279 32.0176 29.3939C31.0516 30.3599 29.8709 30.8505 28.4909 30.8505H17.9109C17.7116 30.8505 17.6196 30.9425 17.6196 31.1265V33.2272C17.6196 33.3959 17.7116 33.4879 17.9109 33.4879H28.4909C29.8556 33.4879 31.1282 33.1505 32.2936 32.4605C33.4589 31.7859 34.3789 30.8505 35.0536 29.6852C35.7282 28.5199 36.0656 27.2472 36.0656 25.8825C36.0656 24.7479 35.8509 23.7205 35.4216 22.7699C36.5869 21.2519 37.1542 19.5499 37.1542 17.6945C37.1542 16.2532 36.7862 14.9192 36.0656 13.6772C35.3449 12.4352 34.3636 11.4539 33.1216 10.7332C31.8796 10.0125 30.5456 9.64453 29.1042 9.64453C26.7429 9.64453 24.7649 10.5339 23.1856 12.2972C21.9589 11.6685 20.5942 11.3619 19.0916 11.3619C16.9296 11.3619 15.0282 12.0365 13.3722 13.3705C11.7162 14.7045 10.6582 16.4219 10.2136 18.4919C8.91023 18.7985 7.76023 19.4272 6.76356 20.3779C5.7669 21.3285 5.06156 22.4479 4.67823 23.7359V23.7972C4.6169 23.9045 4.60156 23.9505 4.60156 23.9965Z"
        fill="#2A2A2A"
      />
      <path
        d="M25.3008 13.8452C26.4048 12.8026 27.6621 12.2812 29.1034 12.2812C30.5908 12.2812 31.8634 12.8179 32.9521 13.8913C34.0254 14.9646 34.5621 16.2526 34.5621 17.7399C34.5621 18.7366 34.3014 19.6719 33.7648 20.5459C32.2928 19.0739 30.5294 18.3533 28.4594 18.3533H27.9381C27.5854 16.6819 26.6961 15.1792 25.3008 13.8452Z"
        fill="#FEC512"
      />
      <path
        d="M0.535156 27.5992C0.535156 27.9366 0.673156 28.2279 0.949156 28.4732C1.20982 28.7339 1.51649 28.8566 1.88449 28.8566H18.7052C19.8398 28.8566 20.8058 28.4579 21.6032 27.6606C22.4005 26.8632 22.7992 25.8972 22.7992 24.7626C22.7992 23.6279 22.4005 22.6772 21.6032 21.8799C20.8058 21.0979 19.8398 20.6992 18.7052 20.6992C17.5398 20.6992 16.5892 21.0826 15.8225 21.8646C15.5772 22.1099 15.4698 22.4319 15.4698 22.7999C15.4698 23.1679 15.5925 23.4746 15.8225 23.7199C16.0525 23.9499 16.3592 24.0726 16.7425 24.0726C17.1105 24.0726 17.4172 23.9499 17.6932 23.7199C17.9845 23.4286 18.3218 23.2906 18.7205 23.2906C19.1192 23.2906 19.4718 23.4286 19.7632 23.7199C20.0545 24.0112 20.2078 24.3639 20.2078 24.7626C20.2078 25.1766 20.0545 25.5292 19.7632 25.8206C19.4718 26.1119 19.1192 26.2652 18.7205 26.2652H1.88449C1.51649 26.2652 1.20982 26.4032 0.933823 26.6639C0.657823 26.9399 0.535156 27.2466 0.535156 27.5992Z"
        fill="#4298B5"
      />
      <path
        d="M0.535156 32.2152C0.535156 32.5832 0.673156 32.8898 0.949156 33.1352C1.20982 33.3958 1.51649 33.5185 1.88449 33.5185H10.9005C11.2992 33.5185 11.6365 33.6565 11.9432 33.9478C12.2498 34.2392 12.4032 34.5918 12.4032 34.9905C12.4032 35.3892 12.2498 35.7572 11.9432 36.0485C11.6365 36.3398 11.2992 36.4932 10.9005 36.4932C10.5018 36.4932 10.1645 36.3398 9.85782 36.0332C9.58182 35.7878 9.27516 35.6652 8.92249 35.6652C8.55449 35.6652 8.24782 35.7878 8.00249 36.0332C7.75716 36.2785 7.63449 36.5852 7.63449 36.9532C7.63449 37.3212 7.75716 37.6278 8.00249 37.8732C8.81516 38.6858 9.78116 39.0998 10.9005 39.0998C12.0352 39.0998 13.0012 38.7012 13.7832 37.9038C14.5805 37.1065 14.9792 36.1405 14.9792 35.0058C14.9792 33.8712 14.5805 32.9052 13.7832 32.1078C12.9858 31.2952 12.0198 30.8965 10.9005 30.8965H1.88449C1.51649 30.8965 1.20982 31.0345 0.933823 31.2952C0.657823 31.5558 0.535156 31.8625 0.535156 32.2152Z"
        fill="#4298B5"
      />
      <path
        d="M17.1445 7.08458C17.1445 7.43725 17.2825 7.74392 17.5585 8.00458L18.4939 9.00125C18.7392 9.24658 19.0612 9.36925 19.4292 9.36925C19.8125 9.36925 20.1192 9.24658 20.3645 9.01658C20.6099 8.78658 20.7325 8.47992 20.7325 8.09658C20.7325 7.72858 20.6252 7.42192 20.3799 7.17658L19.3679 6.17992C19.1225 5.91925 18.8312 5.78125 18.4632 5.78125C18.0952 5.78125 17.7885 5.90392 17.5279 6.16458C17.2672 6.42525 17.1445 6.73192 17.1445 7.08458Z"
        fill="#2A2A2A"
      />
      <path
        d="M27.7852 5.81161C27.7852 6.16428 27.9078 6.47094 28.1685 6.71628C28.4292 6.96161 28.7358 7.08428 29.0885 7.08428C29.4565 7.08428 29.7632 6.96161 30.0238 6.71628C30.2845 6.47094 30.4072 6.17961 30.4072 5.81161V2.66828C30.4072 2.30028 30.2845 1.99361 30.0238 1.73294C29.7632 1.47228 29.4565 1.34961 29.0885 1.34961C28.7205 1.34961 28.4138 1.47228 28.1685 1.73294C27.9232 1.99361 27.7852 2.30028 27.7852 2.66828V5.81161Z"
        fill="#2A2A2A"
      />
      <path
        d="M36.293 9.29258C36.293 9.66058 36.4156 9.96725 36.6456 10.2126C36.9063 10.4732 37.213 10.5959 37.581 10.6113C37.949 10.6266 38.2403 10.4886 38.455 10.2126L40.6936 8.01992C40.9543 7.75925 41.077 7.45258 41.077 7.08458C41.077 6.73192 40.9543 6.42525 40.6936 6.16458C40.433 5.90392 40.1263 5.78125 39.7583 5.78125C39.3903 5.78125 39.0836 5.88858 38.8383 6.13392L36.6456 8.38792C36.4156 8.63325 36.293 8.92458 36.293 9.29258Z"
        fill="#2A2A2A"
      />
      <path
        d="M37.4766 27.4322C37.4766 27.7849 37.5992 28.0916 37.8599 28.3522L38.8412 29.3489C39.1479 29.5942 39.4699 29.7169 39.7919 29.7169C40.0832 29.7169 40.3899 29.5942 40.6966 29.3489C40.9572 29.0882 41.0799 28.7816 41.0799 28.4289C41.0799 28.0916 40.9572 27.7849 40.6966 27.4936L39.7152 26.4969C39.4699 26.2516 39.1632 26.1289 38.8259 26.1289C38.4426 26.1289 38.1206 26.2516 37.8599 26.5122C37.5992 26.7576 37.4766 27.0642 37.4766 27.4322Z"
        fill="#2A2A2A"
      />
      <path
        d="M39.7891 17.7405C39.7891 18.1085 39.9117 18.3999 40.1724 18.6452C40.4024 18.9212 40.6937 19.0439 41.0464 19.0439H44.1437C44.5117 19.0439 44.8184 18.9212 45.0791 18.6605C45.3397 18.3999 45.4624 18.0932 45.4624 17.7405C45.4624 17.3879 45.3244 17.0812 45.0637 16.8205C44.8031 16.5599 44.4964 16.4219 44.1437 16.4219H41.0464C40.6784 16.4219 40.3871 16.5445 40.1417 16.8052C39.9117 17.0659 39.7891 17.3725 39.7891 17.7405Z"
        fill="#2A2A2A"
      />
    </svg>
  );
};

IconUpdraft.propTypes = {
  elementClass: PropTypes.string,
};

export default IconUpdraft;
